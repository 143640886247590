<template>
  <header class="print:hidden">
    <transition name="slide">
      <HeaderMenu
        v-if="showMenu"
        :menu1="model.menu1"
        :menu2="model.menu2"
        :model="model"
        :searchLabel="searchLabel"
        @selfClose="handleMenuButtonClick" />
    </transition>
    <div class="container flex items-center md:py-9 pt-9 pb-0">
      <div class="w-full">
        <a
          href="/"
          title="Tilbake til forsiden"
          class="inline-flex flex-col w-auto md:flex-row md:items-center"
        >
          <span class="hidden md:inline-block text-bluedark-100">
            <inline-svg :src="require('@/assets/images/fhi-logo.svg')"
                        height="44"
                        aria-label="Logoen til FHI"
                        title="Logoen til FHI"
                        class="inline-block -mt-3 fill-current"></inline-svg>
          </span>
          <span v-if="model.header.siteHeading"
                class="inline-block mr-1 text-xl font-normal md:border-l-2 md:border-red-200 md:ml-5 md:pl-5 md:py-1">
            {{model.header.siteHeading}}
          </span>
          <span v-if="model.header.siteHeadingSubTitle"
                class="inline-block text-lg italic font-light md:py-1 mb-4 md:mb-0">
            -&nbsp;{{model.header.siteHeadingSubTitle}}
          </span>
        </a>
      </div>
      <HeaderMenuButton class="md:hidden flex" v-on:click="handleMenuButtonClick" :menuopen="showMenu" />
      <div class="relative hidden justify-end w-1/2 md:w-1/6 md:flex">
        <a :href="model.header.searchPage" title="Gå til søkesiden" class="block mr-6 text-xl md:hidden">
          <span class="sr-only">
            Søk
          </span>
          <font-awesome-icon icon="search" />
        </a>
      </div>
    </div>
    <div class="mb-5 md:border-t border-b border-gray-100 breadcrumb">
      <div class="container md:pr-0 md:pl-0 lg:pr-1 lg:pl-1 flex items-center md:justify-between justify-left">
        <Breadcrumb :model='model' />
        <div class="hidden w-1/2 lg:w-auto md:flex">
          <HeaderSearchForm :searchPage="model.header.searchPageUrl" class="hidden md:block" :label="searchLabel" />
          <HeaderMenuButton class="md:flex hidden" v-on:click="handleMenuButtonClick" :menuopen="showMenu" :searchLabel="searchLabel" />
          <div class="flex md:hidden py-3">
            <HeaderSearchForm :searchPage="model.header.searchPageUrl" :label="searchLabel" />
          </div>
        </div>
      </div>
    </div>
    <div class="container flex items-center md:justify-between justify-left md:p-0">
      <LoginPanel :logoutUrl="model.header.logoutUrl" :userName="model.header.userName"></LoginPanel>
    </div>
  </header>
</template>

<script>
  import HeaderSearchForm from './HeaderSearchForm.vue';
  import HeaderMenuButton from './HeaderMenuButton.vue';
  import HeaderMenu from './HeaderMenu.vue';
  import LoginPanel from './LoginPanel.vue';
  import Breadcrumb from '../breadcrumb/Breadcrumb.vue';

  export default {
    name: 'Header',
    components: {
      HeaderSearchForm,
      HeaderMenuButton,
      HeaderMenu,
      Breadcrumb,
      // LoginPanel,
    },
    props: {
      model: {
        type: Object,
      },
      searchLabel: {
        type: String,
      },
    },
    data() {
      return {
        showMenu: false,
      };
    },
    methods: {
      handleMenuButtonClick() {
        this.showMenu = !this.showMenu;
      },
    },
  };
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
  transform: translateY(0);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-100%);
}
</style>
